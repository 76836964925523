import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Economy } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { DottedLine, Loader, useEconomies, useEntity, useView, EntityFilters, useInfiniteScrollQuery } from '@shapeable/ui';
import { gql } from '@apollo/client';
import { Deposit } from '@shapeable/copper-connect-types';
import { ENTITY_OPEN_GRAPH_FIELDS } from '../../data';
import { DepositCardList } from './deposit-card-list';
import { useCommodities } from '../../hooks/use-commodities';
import { CommodityGroupView } from './commodity-group-main-layout';
import { classNames } from '@shapeable/utils';
import { compact } from 'lodash';
const cls = classNames('commodity-group-deposit-list');

// -------- Types -------->

export type CommodityGroupDepositListProps = Classable & HasChildren & { 
  entity: Entity;
};

export const CommodityGroupDepositListDefaultProps: Omit<CommodityGroupDepositListProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});

const ListStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(3)};
  `,
});

const LoaderStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 330px);
  `,
  tablet: css`
    height: calc(100vh - 312px);
  `,
  desktop: css`
    height: calc(100vh - 366px);
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Loader: styled(Loader)`${LoaderStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
    Body: styled.div`${BodyStyles}`,
      List: styled(DepositCardList)`${ListStyles}`,
  
};

export const CommodityGroupDepositList: Shapeable.FC<CommodityGroupDepositListProps> = (props) => {
  const { className, children, entity } = props;

  const view = entity.path;
  const { activeQueryFilters } = useView<CommodityGroupView>(view, 'deposits');
  // const commodities = useCommodities();
  const economies = useEconomies<Economy & { deposits: Deposit[] }>();

  const { rootRef, items, WaitMessage } = useInfiniteScrollQuery<Deposit, { id: string }>({
    parentKey: 'commodityGroup',
    resultsKey: 'listDeposits',
    variables: { id: entity.id, filter: activeQueryFilters },
    query: gql`
      query CommodityGroupDepositsQuery($id: ID!, $limit: Int, $skip: Int, $filter: DepositFilterInput, $orderBy: [OrderByInput]) {
        commodityGroup(id: $id) {
          listDeposits(filter: $filter, limit: $limit, skip: $skip, orderBy: $orderBy) {
            meta { count total }
            results {
              _entityTypeName
              _schema { label }
              banner { image { url } }
              name path slug id 
              description { text }
              longitude
              latitude
              country { name slug id ${ENTITY_OPEN_GRAPH_FIELDS} }
              region { name slug id }
              commodities { name symbol id ${ENTITY_OPEN_GRAPH_FIELDS} }
            }
          }
        }
      }
    `,
  });

  return (
    <My.Container className={cls.name(className)}>
      <My.Filters 
        view={view} 
        selects={compact([  
          !!economies.length &&  
          {
            name: 'country',
            items: economies,
            pluralLabel: 'Countries',
          },
      ])} />
      <DottedLine gap={5} height={2} />
      <My.Body ref={rootRef}>
        <My.List items={items} />
        <WaitMessage />
      </My.Body>
      <DottedLine gap={5} height={2} />
    </My.Container>
  )
};

CommodityGroupDepositList.defaultProps = CommodityGroupDepositListDefaultProps;
CommodityGroupDepositList.cls = cls;