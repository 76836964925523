import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { CommodityGroup } from '@shapeable/copper-connect-types';
import { breakpoints, theme } from '@shapeable/theme';
import { ImageEntity, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('commodity-group-Aside-layout');

// -------- Types -------->

export type CommodityGroupAsideLayoutProps = Classable & HasChildren & { 
  entity?: CommodityGroup;
};

export const CommodityGroupAsideLayoutDefaultProps: Omit<CommodityGroupAsideLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const ImageStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(5)};
    max-width: calc(100% - ${theme.UNIT(10)});
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Image: styled(ImageEntity)`${ImageStyles}`,
};

export const CommodityGroupAsideLayout: Shapeable.FC<CommodityGroupAsideLayoutProps> = (props) => {
  const { className, children, entity } = props;
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Image entity={entity.openGraph} />
    </My.Container>
  )
};

CommodityGroupAsideLayout.defaultProps = CommodityGroupAsideLayoutDefaultProps;
CommodityGroupAsideLayout.cls = cls;